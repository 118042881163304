import { Stack } from "@mui/material";
import { Chip } from "@nextui-org/react";
import React, { FC } from "react";
import { PresetTrip } from "../../../models/trip.model";
import { useNavigate } from "react-router";

type Props = {
  presetTrip: PresetTrip;
};

const PresetTripCard: FC<Props> = ({ presetTrip }) => {
  const {
    itinerary: { name, duration },
    _id,
  } = presetTrip;

  const navigate = useNavigate();

  function handleNavigate() {
    navigate(`/preset-itinerary/${_id}`);
  }
  return (
    <Stack
      direction={"row"}
      className="h-[106px] bg-white p-3 rounded-2xl items-center cy-preset-trip-card"
      gap={2}
    >
      <img
        src="/images/trip/sample-preset-trip-cover.png"
        className="object-cover rounded-xl w-[90px] h-[90px]"
      />
      <ul className="w-full h-full flex flex-col justify-between">
        <li>
          <p className="font-semibold text-[15px] line-clamp-1">{name}</p>
        </li>
        <li>
          <Chip variant="bordered" className="border-1 text-[10px]">
            {duration} Days
          </Chip>
        </li>
        <li className="flex font-semibold w-full items-center justify-between relative">
          <p className="text-[10px]">Used by 100+ users</p>
          <button onClick={handleNavigate} className="bg-primary px-5 py-2 absolute right-0 bottom-0">
            <p className="text-[10px] underline p-0">See More</p>
          </button>
        </li>
      </ul>
    </Stack>
  );
};

export default PresetTripCard;
