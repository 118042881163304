import { useQuery } from "@tanstack/react-query";
import { fetchPresetTrips } from "../../libs/services/itinerary-preset.service";

export function useFetchPresetTrips() {
  const query = useQuery({
    queryKey: ["fetch all preset trips"],
    queryFn: async () => {
      return await fetchPresetTrips();
    },
  });
  return query;
}
