import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { v4 as uuidv4 } from "uuid";
import ErrorPageWithRedirect from "../../components/common/ErrorPageWithRedirect";
import LoadingPage from "../../components/common/LoadingPage";
import BottomSheetDestination from "../../components/itinerary/destination/BottomSheetDestination";
import { Destination } from "../../components/itinerary/destination/Destination";
import NavBar from "../../components/pages/home/Navbar";
import { coverImages } from "../../configs/coverImages.config";
import { useGenerateItinerary } from "../../hooks/mutation/useGenerateItinerary";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo";
import { saveTrip } from "../../libs/services/trip.service";
import { getUserItineraryLog } from "../../libs/services/user-itinerary-log.service";
import { Membership } from "../../models/classes/Membership.class";
import { StripeCustomer } from "../../models/stripeCustomer.model";

const TRIP_NAME_TO_COUNTRYs = {
  "Korea, Seoul": "Korea",
  "Vietnam, Danang": "Vietnam",
  "Japan, Tokyo": "Japan",
  "Taiwan, Taipei": "Taiwan",
  Singapore: "Singapore",
  "Malaysia, KualaLumpur": "Malaysia",
  "Switzerland, Geneva": "Switzerland",
};

const ConfirmDestinationsPage = () => {
  const navigate = useNavigate();
  const [destinations, setDestinations] = useState<
    { time: string; destination: any }[] | null
  >(null);
  const [itinerary, setItinerary] = useState(null);
  const [searchParams] = useSearchParams();

  const [currentDate, currentCountry, numOfDays, selectedTypes] = [
    searchParams.get("date"),
    searchParams.get("country"),
    searchParams.get("days"),
    searchParams.get("categories")?.split(","),
  ];

  const {
    user,
    loadingUserInfo: isLoadingUser,
    stripeCustomerInfo,
  } = useStripeCustomerInfo();
  const [selectedDestinationId, setSelectedDestinationId] = useState<
    string | null
  >(null);

  const selectedDestination = destinations?.find(
    (destination) => destination.destination.place_id === selectedDestinationId
  );

  const {
    mutate: generateItinerary,
    data: itineraryDetail,
    error: tripDetailError,
    isPending: isFetchingDestinationList,
  } = useGenerateItinerary({
    currentCountry,
    currentDate,
    numOfDays,
    selectedTypes,
  });

  useEffect(() => {
    generateItinerary();
  }, []);

  useEffect(() => {
    if (!itineraryDetail) return;

    setDestinations((_) => {
      var destinations: { time: string; destination: any }[] = [];
      for (const day in itineraryDetail) {
        for (const time in itineraryDetail[day]) {
          const destination = itineraryDetail[day][time];
          destinations.push({ time, destination });
        }
      }
      return [...destinations];
    });

    setItinerary((_) => itineraryDetail);
  }, [itineraryDetail]);

  async function handleAddItinerary(trip: any) {
    if (!user) return navigate("/sign-in");

    const { createdItineraryNum } = await getUserItineraryLog(user.id);

    const isSubscribed = Membership.isValid(
      stripeCustomerInfo as StripeCustomer,
      new Date()
    );

    if (!isSubscribed && createdItineraryNum >= 1) return navigate("/pricing");
    
    await saveTrip(user.id, trip);
  }

  async function handleCreateTrip() {
    try {
      if (tripDetailError) return;

      if (!currentCountry) return;

      const highLevelInformation = {
        name: currentCountry,
        date: currentDate,
        duration: numOfDays,
        imgCover: coverImages[currentCountry],
        city: currentCountry,
        categories: selectedTypes,
        country: TRIP_NAME_TO_COUNTRYs[currentCountry],
      };

      toast.loading("Saving your trip...");

      await handleAddItinerary({
        ...highLevelInformation,
        itinerary,
      });

      toast.dismiss();

      toast.success("Successfully created your trip");

      setTimeout(() => {
        window.location.assign("/");
      }, 1000);
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message, { duration: 4000 });
    }
  }

  if (!currentCountry || !currentDate || !numOfDays || !selectedTypes)
    return <h1>This link is invalid</h1>;

  if (isLoadingUser) return <LoadingPage message="Loading..." />;

  if (!user) {
    toast.error("Please login to continue");
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);
    return null;
  }

  if (tripDetailError) {
    return (
      <ErrorPageWithRedirect
        redirectLink="/"
        message={tripDetailError.message}
        buttonLabel="Back"
      />
    );
  }

  return (
    <div className="overflow-y-scroll h-full w-auto lg:w-4/6 mx-auto px-5 flex flex-col gap-y-[20px] text-black py-2">
      <NavBar user={user} />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="w-full"
      >
        <Stack gap={0.5}>
          <h1 className="text-title font-semibold">My Destinations</h1>
          <p className="text-gray-400 text-sm">(You can edit in next page)</p>
        </Stack>
        <ArrowPathIcon onClick={() => generateItinerary()} className="size-6" />
      </Stack>
      {isFetchingDestinationList ? (
        <LoadingPage message="Curating more destinations..." />
      ) : (
        <Stack
          spacing={2}
          className="lg:p-2 overflow-y-scroll h-full overflow-x-hidden"
        >
          {destinations?.map((destination) => (
            <Destination
              onClick={() =>
                setSelectedDestinationId(destination.destination.place_id)
              }
              key={uuidv4()}
              destination={destination["destination"]}
            />
          ))}
        </Stack>
      )}
      <button
        onClick={handleCreateTrip}
        className="bg-primary rounded-full h-[58px] text-buttonTextColor text-sm"
      >
        Get the plan
      </button>
      <BottomSheet
        open={selectedDestinationId !== null}
        onDismiss={() => {
          setSelectedDestinationId((_) => null);
        }}
        maxHeight={500}
      >
        {selectedDestination && (
          <BottomSheetDestination
            destination={selectedDestination.destination}
          />
        )}
      </BottomSheet>
    </div>
  );
};

export default ConfirmDestinationsPage;
