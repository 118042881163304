import { Trip as TripType } from "../trip.model";
import { Trip } from "./Trip.class";

export class Trips {
  trips: TripType[];

  constructor(trips: TripType[]) {
    this.trips = trips;
  }

  filterPastTrips(): this {
    this.trips = this.trips.filter((trip) => {
      const tripObject = new Trip(trip);
      return (tripObject.getTripEndDate() ?? new Date()) <= new Date();
    });

    return this;
  }

  filterUpcomingTrips(): this {
    this.trips = this.trips.filter((trip) => {
      const tripObject = new Trip(trip);
      return (tripObject.getTripDate() ?? new Date()) >= new Date();
    });
    return this;
  }

  sort(sort: "asc" | "desc"): this {
    this.trips = this.trips.sort((a, b) => {
      if (sort === "asc") {
        return (
          new Date(a.itinerary.date ?? new Date()).getTime() -
          new Date(b.itinerary.date ?? new Date()).getTime()
        );
      } else {
        return (
          new Date(b.itinerary.date ?? new Date()).getTime() -
          new Date(a.itinerary.date ?? new Date()).getTime()
        );
      }
    });

    return this;
  }

  return(): TripType[] {
    return this.trips;
  }
}
