import React from "react";
import Container from "../../components/common/Container";
import { useUser } from "../../hooks/useUser";
import NavBar from "../../components/pages/home/Navbar";
import { useFetchTrips } from "../../hooks/useFetchTrips";
import { Trips } from "../../models/classes/Trips.class";
import PastTripCard from "../../components/specific/past-trip-card/PastTripCard";
import { Stack } from "@mui/material";
import LoadingDestinations from "../itinerary/_components/LoadingDestinations";

const PastTripsPage = () => {
  const { user, loading: isLoadingUser } = useUser();

  const { data: fetchedTrips, isFetching: isLoadingTrips } = useFetchTrips({
    userId: user?.id ?? null,
  });

  const trips = new Trips(fetchedTrips ?? []);

  const isLoading = isLoadingTrips || isLoadingUser;

  return (
    <Container gap={3}>
      <NavBar user={user} />
      <Stack gap={1}>
        <h1 className="text-2xl font-semibold">Past Trips</h1>
        <div className="grid grid-cols-2 gap-1">
          {isLoading
            ? Array.from({ length: 5 }).map((_) => <LoadingDestinations />)
            : trips
                .filterPastTrips()
                .sort("desc")
                .return()
                .map((trip) => <PastTripCard key={trip._id} trip={trip} />)}
        </div>
      </Stack>
    </Container>
  );
};

export default PastTripsPage;
