import moment from "moment";
import { Trip as TripType } from "../trip.model";

export class Trip {
  private trip: TripType;

  constructor(trip: TripType) {
    this.trip = trip;
  }

  getTripDate(): Date | null {
    const { date } = this.trip.itinerary;
    if (!date) return null;
    return new Date(date);
  }

  getTripEndDate(): Date | null {
    const trip = new Trip(this.trip);

    const tripEndDate = moment(trip.getTripDate())
      .add(trip.return().itinerary.duration, "days")
      .toDate();

    return tripEndDate;
  }

  return(): TripType {
    return this.trip;
  }
}
