import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import DayList from "../../components/common/DayList";
import FeedbackButton from "../../components/common/FeedbackButton";
import FeedbackModal from "../../components/common/FeedbackModal";
import LoadingPage from "../../components/common/LoadingPage";
import MapView from "../../components/pages/itinerary/MapView";
import { useFetchPresetTrip } from "../../hooks/fetch-data/useFetchPresetTrip";
import { useModal } from "../../hooks/useModal";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo";
import BackButton from "../itinerary/_components/BackButton";
import Cover from "../itinerary/_components/Cover";
import DateFormModal from "./_components/DateFormModal";

const PresetItineraryPage = () => {
  const { presetId } = useParams();

  const navigate = useNavigate();

  const { user, loadingStripeInfo, loadingUserInfo } = useStripeCustomerInfo();

  const {
    isOpen: isShowFeedback,
    handleClose: onCloseFeedbackModal,
    handleOpen: onOpenFeedbackModal,
  } = useModal();

  const {
    isOpen: isShowForm,
    handleClose: onCloseFormModal,
    handleOpen: onOpenFormModal,
  } = useModal();

  const { data: presetTrip, isFetching: isFetchingPresetTrip } =
    useFetchPresetTrip(presetId);

  const [currentSelectedDay, setCurrentSelectedDay] = useState<string>("");

  const isLoading =
    loadingStripeInfo || loadingUserInfo || isFetchingPresetTrip;

  if (isLoading)
    return (
      <LoadingPage
        message={isFetchingPresetTrip ? "Loading preset trip..." : "Loading..."}
      />
    );

  if (!user) {
    navigate("/sign-in");
    return null;
  }

  if (!presetTrip) {
    return <div>Not found</div>;
  }

  return (
    <div className="w-full lg:hidden h-full gap-y-5 flex flex-col overflow-hidden">
      <BackButton onBackHome={() => navigate("/")} currentSelectedDay={""} />
      <div className="w-full lg:hidden h-full gap-y-5 flex flex-col overflow-hidden">
        <DateFormModal
          isOpen={isShowForm}
          onClose={onCloseFormModal}
          user={user}
          presetId={presetId}
        />
        {user ? (
          <FeedbackModal
            isOpen={isShowFeedback}
            onClose={onCloseFeedbackModal}
            userId={user.id}
          />
        ) : null}
        <FeedbackButton onClick={onOpenFeedbackModal} />
        {currentSelectedDay ? (
          presetTrip ? (
            <MapView
              trip={presetTrip}
              currentSelectedDay={currentSelectedDay}
            />
          ) : null
        ) : (
          <Cover itinerary={presetTrip.itinerary} />
        )}
        {presetId ? (
          <DayList
            tripId={presetId}
            tripDetail={presetTrip.itinerary}
            handleUpdateItinerary={() => {}}
            onSetCurrentSelectedDay={setCurrentSelectedDay}
            currentSelectedDay={currentSelectedDay}
            isPremiumUser={false}
            isPreset={true}
          />
        ) : null}
      </div>
      <Button
        onClick={onOpenFormModal}
        className="m-3 bg-black text-white h-[50px]"
      >
        Make it yours
      </Button>
    </div>
  );
};

export default PresetItineraryPage;
