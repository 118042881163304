import { useQuery } from "@tanstack/react-query";
import { getUserItineraryLog } from "../../libs/services/user-itinerary-log.service";

export function useFetchUserItineraryLog(userId: string) {
  const query = useQuery({
    queryKey: ["user itinerary log", userId],
    enabled: Boolean(userId),
    queryFn: async () => {
      const { createdItineraryNum } = await getUserItineraryLog(userId);
      return createdItineraryNum;
    },
  });
  return query;
}
