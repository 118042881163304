import React, { FC, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import {
  addDestination,
  updateItineraryTime,
} from "../../helper/itinerary.helper";
import { updateTripById } from "../../libs/services/trip.service";
import { Empty } from "../../models/misc.model";
import { TripDetail } from "../../models/trip.model";
import SuggestedDestinations from "../../routes/itinerary/_components/SuggestedDestinations";
import Collapsable from "../Collapsable";
import DestinationEdit from "../DestinationEdit";
import { GoogleDestinationSearchBar } from "../GoogleDestinationSearchBar";
import BottomSheetDestination from "../itinerary/destination/BottomSheetDestination";
import Transportation from "../itinerary/Transportation";
import ListView from "../ListView.";
import { Destination } from "../../models/destination";

type Props = {
  tripDetail: TripDetail | Empty;
  tripId: string;
  handleUpdateItinerary: any;
  onSetCurrentSelectedDay: any;
  currentSelectedDay: string;
  isPremiumUser: boolean;
  isPreset?: boolean;
};

const DayList: FC<Props> = ({
  tripDetail,
  tripId,
  handleUpdateItinerary,
  onSetCurrentSelectedDay,
  isPremiumUser,
  currentSelectedDay,
  isPreset = false,
}) => {
  const [selectedDestination, setSelectedDestination] =
    useState<Destination | null>(null);

  const isTripInThePast = useMemo(() => {
    if (!tripDetail) return false;

    if (!tripDetail.date) return false;

    const currentDate = new Date();

    const [year, month, date] = tripDetail.date.split("-");

    const tripDate = new Date(`${month}/${date}/${year}`);

    return currentDate > tripDate;
  }, [tripDetail?.date]);

  if (!tripDetail) return null;

  async function handleAddGoogleDestination(
    destination: Destination,
    currentDay: string
  ) {
    try {
      if (!tripDetail) return;

      if (!destination) return toast.error("Please select a destination");

      if (!currentDay) return toast.error("Please select a day");

      const doesDestinationExist = Object.values(
        tripDetail.itinerary[currentDay]
      ).find((dest: any) => dest.place_id === destination.place_id);

      if (doesDestinationExist)
        return toast.error("You have already added this place");

      const updatedItinerary = addDestination(
        tripDetail.itinerary,
        destination,
        currentDay
      );

      toast.loading("Adding the destination...");

      await updateTripById(tripId as string, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);

      toast.dismiss();

      toast.success("Added the destination");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleUpdateDestinationTime(
    currentDay: string,
    originTime: string,
    targetTime: string
  ) {
    try {
      if (!tripDetail) return;

      const updatedItinerary = updateItineraryTime(
        tripDetail.itinerary,
        currentDay,
        originTime,
        targetTime
      );

      await updateTripById(tripId as string, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="m-0 px-[10px] lg:px-[20px] h-screen overflow-y-scroll p-0 text-black">
      {selectedDestination ? (
        <BottomSheet
          open={Boolean(selectedDestination)}
          onDismiss={() => {
            setSelectedDestination((_) => null);
          }}
          maxHeight={500}
        >
          {selectedDestination ? (
            <BottomSheetDestination destination={selectedDestination} />
          ) : null}
        </BottomSheet>
      ) : null}
      <ul className="flex flex-col gap-y-4 w-full m-0 p-0">
        {Object.keys(tripDetail.itinerary).map((day, index) => (
          <Collapsable
            index={index}
            isExpanded={day === currentSelectedDay}
            onClick={() => {
              onSetCurrentSelectedDay((prev) => {
                return prev === day ? null : day;
              });
            }}
            key={day}
            title={day}
          >
            <ListView align="flex-start">
              {Object.values(tripDetail.itinerary[day]).map(
                (destination: any, index) => {
                  const destinations: any[] = Object.values(
                    tripDetail.itinerary[day]
                  );

                  return (
                    <div
                      className="flex flex-col gap-y-3 w-full"
                      key={destination.name_place}
                    >
                      <DestinationEdit
                        itinerary={tripDetail.itinerary}
                        isTrashVisible={!(isTripInThePast || isPreset)}
                        onUpdateItinerary={handleUpdateItinerary}
                        key={destination["name_place"]}
                        editAble={!(isTripInThePast || isPreset)}  
                        currentDay={day}
                        onUpdateItineraryTime={handleUpdateDestinationTime}
                        time={Object.keys(tripDetail.itinerary[day])[index]}
                        destination={destination}
                        tripId={tripId}
                        onClick={() =>
                          selectedDestination === destination
                            ? setSelectedDestination(null)
                            : setSelectedDestination(destination)
                        }
                      />

                      {(index < destinations.length - 1) ? (
                        isTripInThePast ? null : (
                          <Transportation
                            originPlaceId={destination.place_id}
                            targetPlaceId={destinations[index + 1].place_id}
                          />
                        )
                      ) : null}
                    </div>
                  );
                }
              )}
            </ListView>
            {(isTripInThePast || isPreset ) ? null : (
              <div className="my-[15px]">
                <GoogleDestinationSearchBar
                  targetCountry={tripDetail.country}
                  currentDay={day}
                  onAddDestination={handleAddGoogleDestination}
                />
              </div>
            )}
            {(isTripInThePast || isPreset ) ? null : (
              <div className="mt-5">
                <SuggestedDestinations
                  currentDay={day}
                  itinerary={tripDetail}
                  isPremiumUser={isPremiumUser}
                />
              </div>
            )}
          </Collapsable>
        ))}
      </ul>
    </div>
  );
};

export default DayList;
