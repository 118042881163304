import { toast } from "react-hot-toast";
import { fetchTripsByUserId } from "../libs/actions/itinerary";
import { useQuery } from "@tanstack/react-query";

type Params = {
  userId: string | null;
};

export const useFetchTrips = ({ userId }: Params) => {
  const query = useQuery({
    queryKey: ["fetching all trips by user id", userId],
    queryFn: async () => {
      try {
        if (!userId) return [];
        return await fetchTripsByUserId(userId);
      } catch (error) {
        toast.error(error.message);
        return [];
      }
    },
    enabled: Boolean(userId),
  });
  return query;
};
