import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { useNavigate } from "react-router";
import { useItineraryPageContext } from "../../contexts/ItineraryPageContext.tsx";
import { Membership } from "../../models/classes/Membership.class.ts";
import BackButton from "./_components/BackButton";
import DesktopLayout from "./_components/DesktopLayout";
import ItineraryPDF from "./_components/ItineraryPDF.tsx";
import MobileLayout from "./_components/MobileLayout";
import PDFButton from "./_components/PDFButton.tsx";

function ItineraryPage() {
  const navigate = useNavigate();

  const { itinerary, stripeCustomer, currentSelectedDay } =
    useItineraryPageContext();

  const isMemberShip = stripeCustomer
    ? stripeCustomer.stripeCustomerInfo
      ? Membership.isValid(stripeCustomer.stripeCustomerInfo)
      : false
    : false;

  return (
    <div className="relative flex flex-col w-full h-screen lg:h-screen overflow-hidden lg:p-5 bg-[#FCFCFC]">
      <BackButton
        onBackHome={() => navigate("/")}
        currentSelectedDay={currentSelectedDay}
      />
      {isMemberShip ? (
        <PDFDownloadLink
          document={<ItineraryPDF itinerary={itinerary} />}
          fileName="itinerary.pdf"
        >
          <PDFButton />
        </PDFDownloadLink>
      ) : null}
      <DesktopLayout />
      <MobileLayout />
    </div>
  );
}

export default ItineraryPage;
