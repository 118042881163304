import { itineraryManagementClient } from "../../configs/axios.config";
import { Trip } from "../../models/trip.model";

export async function saveTrip(userId: string, trip: any) {
  try {
    await itineraryManagementClient.post(`/itinerary?userId=${userId}`, {
      trip,
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function fetchTripById(tripId: string): Promise<Trip | null> {
  try {
    const response = await itineraryManagementClient.get(
      `/itinerary?tripId=${tripId}`
    );
    return response.data.itinerary;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function updateTripById(tripId: string, newItinerary: any) {
  try {
    await itineraryManagementClient.put(`/itinerary?tripId=${tripId}`, {
      newItinerary,
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function deleteTripById(tripId: string) {
  try {
    await itineraryManagementClient.delete(`/itinerary?tripId=${tripId}`);
  } catch (error) {
    throw new Error(error.message);
  }
}