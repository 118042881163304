import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { colors } from "../../configs/colors.config";

mapboxgl.accessToken = process.env.MAP_BOX_PUBLIC_ACCESS_TOKEN;

const markerStyles = {
  backgroundColor: colors.lightSalmon,
  padding: "5px",
  width: "60px",
  height: "60px",
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
};

const markerContentStyles = {
  fontSize: "1rem",
  color: "white",
  fontWeight: "bold"
};

const applyStyles = (element, styles) => {
  Object.entries(styles).forEach(([key, value]) => {
    element.style[key] = value;
  });
};

const createMarker = (index) => {
  const marker = document.createElement("div");
  const markerContent = document.createElement("div");

  applyStyles(marker, markerStyles);
  markerContent.textContent = index + 1;
  applyStyles(markerContent, markerContentStyles);

  marker.append(markerContent);
  return marker;
};

const Mapbox = ({ lat, lng, zoom, destinations }) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    // if (map.current) return; // Initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.current.on("load", () => {
      map.current.resize();

      destinations.forEach((destination, index) => {
        const marker = createMarker(index);
        const { lng, lat } = destination.location;
        new mapboxgl.Marker(marker).setLngLat({ lng, lat }).addTo(map.current);
      });
    });

    return () => map.current.remove();
    
  }, [lat, lng, zoom, destinations]);

  return <div className="w-full h-full rounded-2xl" ref={mapContainerRef} />;
};

export default Mapbox;
