import axios from "axios";

const SUCCESS_CODES = [200, 201];

export const itineraryManagementClient = axios.create({
  baseURL: `${process.env.ITINERARY_MANAGEMENT_ENDPOINT}/v1`,
});

export const itineraryGeneratorClient = axios.create({});

export const paymentClient = axios.create({
  baseURL: import.meta.env.VITE_PAYMENT_API_ENDPOINT,
});

itineraryManagementClient.interceptors.response.use((response) => {
  if (!SUCCESS_CODES.includes(response.status)) {
    throw new Error(response.data.error.message);
  }
  return response;
});

itineraryGeneratorClient.interceptors.response.use((response) => {
  if (!SUCCESS_CODES.includes(response.status)) {
    throw new Error(response.data.error.message);
  }
  return response;
});

paymentClient.interceptors.response.use((response) => {
  if (!SUCCESS_CODES.includes(response.status)) {
    throw new Error(response.data.error.message);
  }
  return response;
});
