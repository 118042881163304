import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import OAuthButton from "../../../components/common/OAuthButton";
import { useUser } from "../../../hooks/useUser";
import BreakLine from "./_components/BreakLine";

export const SignInPage = () => {
  const { user, loading } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/");
  }, [loading]);

  return (
    <main className="bg-white flex flex-col items-start justify-center h-full w-full px-10 gap-y-8">
      <div className="bg-primary w-[63px] h-[63px] rounded-full"></div>
      <h1 className="text-title w-[80%] font-medium leading-7 text-black">
        Sign in to unlock the best of Eleplan.ai
      </h1>
      <ul className="flex flex-col gap-y-5 w-full">
        <OAuthButton provider="google" />
        {/* <OAuthButton provider="apple" /> */}
        {/* <OAuthButton provider="facebook" /> */}
      </ul>
      <BreakLine />
      <OAuthButton provider="email" />
    </main>
  );
};

export default SignInPage;
