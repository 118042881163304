import { itineraryManagementClient } from "../../configs/axios.config";

export async function fetchTripsByUserId(userId: string) {
  try {
    const response = await itineraryManagementClient.get(
      `/itinerary?userId=${userId}`
    );
    return response.data.itinerary;
  } catch (error) {
    throw new Error(error.message);
  }
}
