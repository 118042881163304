import React, { FC, useEffect, useState } from "react";
import {
  MapViewProvider,
  useMapContext,
} from "../../../contexts/MapViewContext.tsx";
import { getDestinationLatLng } from "../../../helper/googlePlace.helper.ts";
import { PresetTrip, Trip } from "../../../models/trip.model.ts";
import Mapbox from "../../map/Mapbox.jsx";

type Props = {
  trip: Trip | PresetTrip;
  currentSelectedDay: string;
};

const MapView: FC<Props> = ({ trip, currentSelectedDay }) => {
  return (
    <MapViewProvider trip={trip} currentSelectedDay={currentSelectedDay}>
      <main className="lg:h-full h-[390px] w-full flex gap-x-3 box-border overflow-hidden">
        <MapBackground />
      </main>
    </MapViewProvider>
  );
};

const MapBackground = () => {
  const { destinations } = useMapContext();
  const [serializedDestinations, setSerializedDestinations] = useState<any[]>(
    []
  );

  useEffect(() => {
    const fetchLocations = async () => {
      const fetcedDestinationsWithLocations = destinations.map(
        async (destination) => {
          const placeId = destination.place_id;

          const {
            location: { latitude, longitude },
          } = await getDestinationLatLng(placeId);

          const serialized_destination = {
            ...destination,
            location: {
              lat: latitude as number,
              lng: longitude as number,
            },
          };
          return serialized_destination;
        }
      );

      const fetcedDestinationsWithLocationsPromise = await Promise.all(
        fetcedDestinationsWithLocations
      );

      setSerializedDestinations((_) => fetcedDestinationsWithLocationsPromise);
    };

    fetchLocations();
  }, [destinations]);

  if (serializedDestinations.length <= 0)
    return (
      <main className="relative w-full h-[450px]">
        <div className="absolute top-[35%] translate-y-[-50%] left-1/2 w-[40px] h-[40px] translate-x-[-50%]">
          <div className="w-full h-full border-[4px] border-solid border-r-blue-500 animate-spin rounded-full "></div>
        </div>
      </main>
    );

  return (
    <>
      <aside className="relative w-full h-full rounded-3xl overflow-hidden">
        <Mapbox
          zoom={9}
          destinations={serializedDestinations}
          lat={serializedDestinations[0].location.lat}
          lng={serializedDestinations[0].location.lng}
        />
      </aside>
    </>
  );
};

export default MapView;
