import React from "react";
import { useFetchPresetTrips } from "../../../hooks/fetch-data/useFetchPresetTrips";
import PresetTripCard from "../../../components/specific/trip-card/PresetTripCard";
import { Stack } from "@mui/material";
import { CircularProgress } from "@nextui-org/react";

const CuratedTravelExperiences = () => {
  const { data: presetTrips, isFetching: isFetchingPresetTrips } =
    useFetchPresetTrips();

  if (isFetchingPresetTrips) {
    return (
      <Stack className="h-[400px] items-center justify-center">
        <CircularProgress label="Loading preset trips..." />
      </Stack>
    );
  }

  return (
    <section className="w-full h-full mt-10 bg-[#F1F1F1] p-5">
      <h1 className="text-title w-1/2 font-semibold leading-7">
        Curated Travel Experiences
      </h1>
      <Stack className="w-full mt-5" gap={1}>
        {presetTrips?.map((presetTrip) => (
          <PresetTripCard key={presetTrip._id} presetTrip={presetTrip} />
        ))}
      </Stack>
    </section>
  );
};

export default CuratedTravelExperiences;
