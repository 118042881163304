import React from "react";
import DayList from "../../../components/common/DayList";
import MapView from "../../../components/pages/itinerary/MapView";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { Membership } from "../../../models/classes/Membership.class";
import Cover from "./Cover";

function DesktopLayout({}) {
  const {
    currentSelectedDay,
    itinerary,
    trip,
    setCurrentSelectedDay,
    tripId,
    handleUpdateItinerary,
    stripeCustomer: { stripeCustomerInfo },
  } = useItineraryPageContext();

  return (
    <div className="w-full h-full hidden lg:flex gap-y-5 lg:gap-y-0">
      <div className="lg:w-1/2 w-full h-full box-border">
        {currentSelectedDay !== null ? (
          trip ? (
            <MapView trip={trip} currentSelectedDay={currentSelectedDay} />
          ) : null
        ) : (
          <Cover itinerary={itinerary} />
        )}
      </div>
      <div className="lg:w-1/2 w-full h-full overflow-y-scroll no-scrollbar">
        {tripId ? (
          <DayList
            tripId={tripId}
            tripDetail={itinerary}
            handleUpdateItinerary={handleUpdateItinerary}
            onSetCurrentSelectedDay={setCurrentSelectedDay}
            currentSelectedDay={currentSelectedDay}
            isPremiumUser={Membership.isValid(stripeCustomerInfo)}
          />
        ) : null}
      </div>
    </div>
  );
}

export default DesktopLayout;
