import React from "react";
import DayList from "../../../components/common/DayList";
import FeedbackButton from "../../../components/common/FeedbackButton";
import FeedbackModal from "../../../components/common/FeedbackModal";
import MapView from "../../../components/pages/itinerary/MapView";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { useModal } from "../../../hooks/useModal";
import { Membership } from "../../../models/classes/Membership.class";
import Cover from "./Cover";

function MobileLayout() {
  const {
    currentSelectedDay,
    itinerary,
    trip,
    tripId,
    handleUpdateItinerary,
    setCurrentSelectedDay,
  } = useItineraryPageContext();

  const { isOpen: isShowFeedback, handleClose, handleOpen } = useModal();

  const {
    stripeCustomer: { user, stripeCustomerInfo },
  } = useItineraryPageContext();

  return (
    <div className="w-full lg:hidden h-full gap-y-5 flex flex-col overflow-hidden">
      {user ? (
        <FeedbackModal
          isOpen={isShowFeedback}
          onClose={handleClose}
          userId={user.id}
        />
      ) : null}
      <FeedbackButton onClick={handleOpen} />
      {currentSelectedDay !== null ? (
        trip ? (
          <MapView trip={trip} currentSelectedDay={currentSelectedDay} />
        ) : null
      ) : (
        <Cover itinerary={itinerary} />
      )}

      {tripId ? (
        <DayList
          tripId={tripId}
          tripDetail={itinerary}
          handleUpdateItinerary={handleUpdateItinerary}
          onSetCurrentSelectedDay={setCurrentSelectedDay}
          currentSelectedDay={currentSelectedDay}
          isPremiumUser={Membership.isValid(stripeCustomerInfo)}
        />
      ) : null}
    </div>
  );
}

export default MobileLayout;
