import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { Stack } from "@mui/material";
import { RangeCalendar } from "@nextui-org/calendar";
import { Button, RangeValue } from "@nextui-org/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import LoadingPage from "../../components/common/LoadingPage";
import RoundedMultipleChoices from "../../components/forms/RoundedMultipleChoices";
import NavBar from "../../components/pages/home/Navbar";
import SearchInput from "../../components/SearchBar";
import { useScreen } from "../../hooks/useScreen";
import { useUser } from "../../hooks/useUser";
import { getTimeDifferenceInternationalTimes } from "../../libs/utils/date.util";
import { CITIES_MAX_TRIP_DAYS, countries } from "../../models/countries";
import { FaRegCircleXmark } from "react-icons/fa6";

const CALENDAR_OFFSET = 40;
const TRIP_MATE_OPTIONS = ["Solo", "Lover", "Friends", "Family"];

type FormFields = {
  currentCountry: string;
  tripMate: string[];
};

const ItineraryInformationPage = () => {
  const [searchParams] = useSearchParams();
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { screenWidth } = useScreen();

  const country = searchParams.get("country");

  const [tripDate, setTripDate] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()),
    end: today(getLocalTimeZone()).add({ weeks: 1 }),
  });

  const { register, watch, setValue, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      currentCountry: country || "",
      tripMate: [TRIP_MATE_OPTIONS[0]],
    },
  });

  const totalDays = getTimeDifferenceInternationalTimes(
    tripDate.start,
    tripDate.end
  );

  const MAXIMUM_DAYS = CITIES_MAX_TRIP_DAYS[watch().currentCountry] || 0;

  const doesExceedDayLimit = totalDays > MAXIMUM_DAYS;

  const { tripMate, currentCountry } = watch();

  function onSubmit() {
    try {
      if (!currentCountry) return toast.error("Please select a country");

      if (!currentCountry) return toast.error("Please fill all the forms");

      if (!countries.includes(currentCountry))
        return toast.error(`${currentCountry} is not a valid city`);

      if (doesExceedDayLimit)
        return toast.error(`The trip must be less than ${MAXIMUM_DAYS} days`);

      if (tripMate.length < 1)
        return toast.error("Please select at least one trip mate");

      navigate(
        `/create-itinerary?country=${currentCountry}&days=${totalDays}&tripMate=${tripMate.join(
          ","
        )}&step=2&date=${tripDate.start}`
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (loading) return <LoadingPage message="Loading..." />;

  if (!user) {
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);

    return null;
  }

  return (
    <Stack className="flex flex-col gap-y-10 lg:gap-y-16 items-left lg:items-center w-[90%] lg:w-[40%] my-0 pt-2 mx-auto h-[80%] text-black">
      <NavBar user={user} />
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <h1 className="text-title leading-7 font-semibold lg:w-max w-4/5 mb-7">
          Tell us how you'd like your trip to be
        </h1>
        <Stack width={"100%"} spacing={4}>
          <SearchInput
            value={currentCountry}
            inputElement={
              <label className="form-control w-full relative">
                <div className="label">
                  <span className="label-text">
                    Where would you love to explore next?
                  </span>
                </div>
                <input
                  {...register("currentCountry")}
                  type="text"
                  placeholder="Ex. Sydney, Time Square"
                  className="input input-bordered w-full text-sm bg-white"
                />
                {watch().currentCountry ? (
                  <FaRegCircleXmark
                    onClick={() => {
                      setValue("currentCountry", "");
                    }}
                    size={20}
                    className="absolute bottom-[15%] right-2 text-gray-500"
                  />
                ) : null}
              </label>
            }
            onSelect={(country: string) => setValue("currentCountry", country)}
            items={countries}
          />
          <RangeCalendar
            calendarWidth={screenWidth - CALENDAR_OFFSET}
            bottomContent={
              currentCountry ? (
                <p
                  className={`w-full text-center text-sm pb-4 ${
                    doesExceedDayLimit
                      ? "text-red-500 font-light animate-error-shake"
                      : ""
                  }`}
                >
                  {doesExceedDayLimit
                    ? `The trip must be less than ${MAXIMUM_DAYS} days`
                    : `Total Days :  ${totalDays}`}
                </p>
              ) : null
            }
            aria-label="Date (Uncontrolled)"
            minValue={today(getLocalTimeZone())}
            value={tripDate}
            onChange={setTripDate}
          />
          <RoundedMultipleChoices
            options={TRIP_MATE_OPTIONS}
            currentValue={tripMate}
            setter={(newValue: string[]) => setValue("tripMate", newValue)}
            label="Who's joining your trip?"
          />
        </Stack>
        <Button
          className="bg-primary min-h-[56px] rounded-full text-buttonTextColor font-semibold mt-10"
          type="submit"
          fullWidth
        >
          Continue
        </Button>
      </form>
    </Stack>
  );
};

export default ItineraryInformationPage;
