import { supabase } from "../../configs/db.config";
import { Feedback } from "../../models/feedback.model";

export async function submitFeedBack(
  feedback: Pick<Feedback, "score" | "comment" | "user_id">
) {
  try {
    const { error } = await supabase.from("feedbacks").insert(feedback);
    if (error) throw new Error(error.message);
  } catch (error: any) {
    console.error(`Failed to submit feedback: ${error.message}`);
    throw new Error("Failed to submit feedback");
  }
}
