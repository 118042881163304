import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { deleteTripById } from "../libs/services/trip.service";
import { Membership } from "../models/classes/Membership.class";
import { Empty } from "../models/misc.model";
import { StripeCustomer } from "../models/stripeCustomer.model";

type Params = {
  stripeCustomerInfo: StripeCustomer | Empty;
};

export function useManageTrip({ stripeCustomerInfo }: Params) {
  const navigate = useNavigate();

  const handleDeleteTrip = async (tripId: string) => {
    try {
      toast.loading("Deleting the trip...");

      await deleteTripById(tripId);

      toast.dismiss();

      toast.success("Deleted the trip");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleNavigateCreateTrip = async (e: any) => {
    e.preventDefault();

    if (!stripeCustomerInfo) {
      toast.error("Please sign in before building a trip");
      setTimeout(() => {
        navigate("/sign-in");
      }, 1500);
      return;
    }

    if (!Membership.isValid(stripeCustomerInfo)) {
      const hasReachedLimit = await Membership.hasReachedFreeItineraryLimit(
        stripeCustomerInfo.user_id
      );

      if (hasReachedLimit) {
        navigate("/pricing");
        return;
      }
    }

    navigate("/itinerary-information");
  };

  return { handleDeleteTrip, handleNavigateCreateTrip };
}
