import React from "react";
import "../../App.css";
import SafeArea from "../../components/common/SafeArea";
import NavBar from "../../components/pages/home/Navbar";
import { useUser } from "../../hooks/useUser";
import BlogSection from "./_components/BlogSection";
import ItineraryList from "./_components/ItineraryList";
import CuratedTravelExperiences from "./_components/CuratedTravelExperiences";

function HomePage() {
  const { user } = useUser();
  return (
    <div className="flex flex-col justify-start items-center min-h-screen overflow-x-hidden w-full lg:px-[10%] overflow-scroll bg-[#FCFCFC] pt-2">
      <SafeArea>
        <NavBar user={user} />
      </SafeArea>
      <SafeArea>
        <ItineraryList />
        {/* <BlogSection /> */}
      </SafeArea>
      <CuratedTravelExperiences />
    </div>
  );
}

export default HomePage;
