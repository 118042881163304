import React, { FC } from "react";
import {
  addDaysForTripCardDate,
  formatTripCardDate,
} from "../../../libs/utils/date.util";

type Props = {
  itinerary: any;
};

const TripDateDetail: FC<Props> = ({ itinerary }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center gap-x-1.5 text-[15px] text-black font-semibold">
        <p className="p-0 m-0">{formatTripCardDate(itinerary.date)}</p>
        <p className="p-0 m-0">-</p>
        <p className="p-0 m-0">
          {
            addDaysForTripCardDate(
              itinerary.date,
              Number(itinerary.duration),
              "string"
            ) as string
          }
        </p>
        <p className="p-0 m-0">{`(${
          itinerary.duration !== 1
            ? `${itinerary.duration} days`
            : `${itinerary.duration} day`
        })`}</p>
      </div>
    </div>
  );
};

export default TripDateDetail;
