import toast from "react-hot-toast";
import { itineraryManagementClient } from "../../configs/axios.config";
import { PresetTrip } from "../../models/trip.model";

export async function fetchPresetTrips(): Promise<PresetTrip[] | null> {
  try {
    const response = await itineraryManagementClient.get("/itinerary-preset");
    return response.data;
  } catch (error: any) {
    toast.error(error.message);
    return null;
  }
}

export async function fetchPresetTrip(
  presetTripId: string
): Promise<PresetTrip | null> {
  try {
    const response = await itineraryManagementClient.get(
      `/itinerary-preset/${presetTripId}`
    );
    return response.data.data;
  } catch (error: any) {
    toast.error(error.message);
    return null;
  }
}

export async function clonePresetTrip(
  userId: string,
  presetTripId: string,
  tripDate: string
) {
  console.log(tripDate)
  if (/(\d{4})-(\d{2})-(\d{2})/.test(tripDate) === false)
    throw new Error("Invalid date format");

  await itineraryManagementClient.post(
    `/itinerary-preset/clone/${userId}?presetTripId=${presetTripId}&tripDate=${tripDate}`
  );
}
