import { Stack } from "@mui/material";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { deleteDestination } from "../helper/itinerary.helper";
import { updateTripById } from "../libs/services/trip.service";
import { Destination } from "../models/destination";
import { Tag } from "./itinerary/Tag";

type Props = {
  destination: Destination;
  time: string;
  editAble?: boolean;
  onUpdateItineraryTime: any;
  currentDay: string;
  onUpdateItinerary: any;
  isTrashVisible?: boolean;
  displayTime?: boolean;
  itinerary: any;
  tripId: string;
  onClick: any;
};

const timeStyle = {
  fontSize: "10px",
  transform: "rotate(-90deg) translateY(-20px)",
  margin: "0",
  padding: "0",
  color: "black",
  backgroundColor: "white",
  minWidth: "50px",
};

const DestinationEdit: FC<Props> = ({
  destination,
  time,
  editAble = true,
  onUpdateItinerary,
  onUpdateItineraryTime,
  currentDay,
  isTrashVisible = false,
  displayTime = true,
  itinerary,
  tripId,
  onClick,
}) => {
  const [destinationTime, setDestinationTime] = useState(time);

  function onTimeInput(e: any) {
    e.stopPropagation();
    try {
      const currentTime = e.target.value;
      const doesCurrentTimeOverlap = Object.keys(itinerary[currentDay]).some(
        (time) => {
          return time === currentTime;
        }
      );
      if (doesCurrentTimeOverlap)
        throw new Error("Cannot have two destinations at the same time");

      setDestinationTime(currentTime);
      onUpdateItineraryTime(currentDay, time, currentTime);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleDeleteDestination() {
    try {
      const doesCurrentHaveOneDestination =
        Object.keys(itinerary[currentDay]).length <= 1;
      if (doesCurrentHaveOneDestination)
        throw new Error("Cannot delete the last destination");

      toast.loading("Deleting destination...");

      const updatedItinerary = deleteDestination(
        itinerary,
        currentDay,
        destination
      );

      await updateTripById(tripId, updatedItinerary);

      setTimeout(() => {
        toast.dismiss();

        toast.success("Successfully deleted the destination");

        onUpdateItinerary(updatedItinerary);
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div onClick={onClick} className="flex gap-5 items-center relative w-full">
      {displayTime && (
        <input
          type="time"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onInput={onTimeInput}
          value={destinationTime}
          style={timeStyle}
          disabled={!editAble}
        />
      )}
      <Detail displayTime={displayTime}>
        <h4 style={{ margin: "0", fontSize: "13px", fontWeight: "600" }}>
          {destination["name_place"]}
        </h4>
        {Boolean(destination.category) ? (
          destination.category?.includes(",") ? (
            <Stack
              sx={{
                maxWidth: "100%",
              }}
              spacing={1}
              direction={"row"}
            >
              {destination.category.split(",").map((tag) => {
                return <Tag key={tag} content={tag} />;
              })}
            </Stack>
          ) : (
            <Tag content={destination.category} />
          )
        ) : null}
      </Detail>
      {isTrashVisible && <DeleteButton onClick={handleDeleteDestination} />}
    </div>
  );
};

const DeleteButton = ({ onClick }) => {
  return (
    <FaTrashAlt
      className="absolute top-0 right-0 text-primary cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    />
  );
};

const Detail = ({ children, displayTime }) => {
  const detailClass = classNames(
    "flex",
    "flex-col",
    "gap-[5px]",
    "justify-center",
    "ml-[15px]",
    {
      "transform translate-x-[-40px]": displayTime,
      "transform translate-x-0": !displayTime,
    }
  );

  return <div className={detailClass}>{children}</div>;
};

export default DestinationEdit;
