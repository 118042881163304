import React, { FC, ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

type Props = {
  items: string[];
  onSelect: any;
  value: string;
  inputElement: ReactNode;
};

const SearchInput: FC<Props> = ({ items, onSelect, value, inputElement }) => {
  const filteredDestinations = items?.filter((item) =>
    item.toLowerCase().includes(value.toLowerCase())
  );
  const displayItem =
    (filteredDestinations.length > 0) &&
    value !== filteredDestinations[0] && Boolean(value)

  return (
    <div>
      {inputElement}
      {displayItem && (
        <div className="bg-white border-solid border-gray-300 border-1 rounded-xl flex flex-col mt-3 overflow-hidden">
          {items.map((item, index) =>
            item.includes(value) ? (
              <h1
                key={uuidv4()}
                onClick={() => {
                  onSelect(item);
                }}
                className={`text-[0.85rem] font-semibold cursor-pointer border-b ${
                  index < filteredDestinations.length - 1
                    ? "border-solid"
                    : "border-none"
                } border-gray-300 py-4 pl-4 flex items-center`}
              >
                {item}
              </h1>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
