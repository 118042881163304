import { Stack } from "@mui/material";
import React, { FC } from "react";
import Rating from "../../common/Rating";
import { MapIcon } from "@heroicons/react/24/outline";
import { Tag } from "../Tag";
import {
  formatDestinationDescription,
  getDestinationMapUrl,
} from "../../../helper/destination.helper";
import { Destination } from "../../../models/destination";
import { formatDestinationWeekText } from "../../../helper/util";

const BottomSheetDestination: FC<{
  destination: Pick<
    Destination,
    "name_place" | "description" | "category" | "rating" | "opening_hours"
  >;
}> = ({ destination }) => {
  const { name_place, description, category, rating, opening_hours } =
    destination;
  const googleMapUrl = name_place ? getDestinationMapUrl(name_place) : null;
  const categories = category?.split(",");

  function handleNavigateGoogleMapUrl() {
    if (!googleMapUrl) return;
    window.open(googleMapUrl, "_blank");
  }

  return (
    <Stack gap={2} padding={3} className="text-black">
      <nav className="flex justify-between">
        <Stack direction={"row"} className="w-[85%]">
          <h1 className="text-xl font-semibold line-clamp-1">{name_place}</h1>
          <Rating rating={rating ?? 0} />
        </Stack>
        <MapIcon
          onClick={() => {
            handleNavigateGoogleMapUrl();
          }}
          className="size-6"
        />
      </nav>
      <Stack direction={"row"} gap={1}>
        {categories?.map((category) => (
          <Tag key={category} content={category} />
        ))}
      </Stack>
      <h2 className="font-semibold">Description</h2>
      {formatDestinationDescription(description ?? "").map((desc, index) => (
        <p key={index} className="text-gray-400 text-sm h-full">
          {desc}
        </p>
      ))}
      <h2 className="font-semibold">
        Time Available{" "}
        <span className="text-gray-400 font-bold">(by Google Maps)</span>
      </h2>
      <Stack>
        {opening_hours ? (
          opening_hours.weekday_text.map((dayWithTime, index) => {
            const [day, time] = formatDestinationWeekText(dayWithTime);
            return (
              <p key={index} className="text-black text-sm h-full">
                <span className="font-semibold">{day}</span> : {time}
              </p>
            );
          })
        ) : (
          <p className="text-center text-gray-400">No information</p>
        )}
      </Stack>
    </Stack>
  );
};

export default BottomSheetDestination;
