import { useQuery } from "@tanstack/react-query";
import { fetchPresetTrip } from "../../libs/services/itinerary-preset.service";
import { Empty } from "../../models/misc.model";

export function useFetchPresetTrip(presetTripId: string | Empty) {
  const query = useQuery({
    queryKey: ["fetch preset trip", presetTripId],
    queryFn: async () => {
      if (!presetTripId) return null;
      return await fetchPresetTrip(presetTripId);
    },
  });

  return query;
}
